<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { getFreshlyBakedRoomList, delCase, isStopCase, getUserCopy } from "@/api/freshlyBaked/base/goodsCase.js";

export default {
  name: "goodsCase",
  components: { TablePage },
  data() {
    return {
      options: {
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        getListApi: getFreshlyBakedRoomList,
        mutiSelect: true, // 多选
        listNo: true, // 序号
        title: "现烤商品方案管理",
        check: [], // 选中数据
        rowKey: "freshlyBakedCaseId",
        search: [
          // {
          //   type: "input",
          //   tip: "现烤商品编号/现烤商品名称",
          //   model: "",
          //   filter: "query",
          // },
          {
            type: "filters",
            tip: "全部/现烤商品方案编号/现烤商品方案名称",
            isRestore:true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "freshlyBakedCaseNos", label: "现烤商品方案编号" },
              { filter: "freshlyBakedCaseNames", label: "现烤商品方案名称" },
            ],
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "start",
            label: "启用",
            alertText: "确认要启用选中方案吗？",
            btnType: "dropdown",
            other: [
              { click: "stop", label: "禁用", alertText: "确认要禁用选中方案吗？" },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "copy",
            label: "复制",
            type: "success",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "del",
            alertText: "确认要删除选中方案吗？",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
        ], // 现烤间商品方案编号、现烤间商品方案名称、方案状态、创建人、创建时间、修改人、修改时间、备注
        columns: [
          {
            prop: "freshlyBakedCaseNo",
            label: "现烤间商品方案编号",
            type: "link",
            click: "update",
            minWidth: 130,
          },
          {
            prop: "freshlyBakedCaseName",
            label: "现烤间商品方案名称",
            minWidth: 130,
          },
          {
            prop: "isStop",
            label: "方案状态",
            formatter: (v) => (v ? "禁用" : "启用"),
            minWidth: 100,
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 150,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 80,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 150,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 80,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 150,
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "update":
          this.$router.push({
              name: "freshlyBakedGoodsCaseDetail",
              query: { freshlyBakedCaseId: row.freshlyBakedCaseId, type: "Update" },
            });
          break;
        case "add":
          {
            this.$router.push({ name: "freshlyBakedGoodsCaseDetail" });
          }
          break;
        case "del":
          try {
            await delCase(this.options.check.map((x) => x.freshlyBakedCaseId));
            this.$message.success("删除成功！");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        case "start":
          try {
            await isStopCase({
              isStop: false,
              printCaseIds: this.options.check.map((x) => x.freshlyBakedCaseId),
            });
            this.$message.success("操作成功！");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        case "stop":
          try {
            await isStopCase({
              isStop: true,
              printCaseIds: this.options.check.map((x) => x.freshlyBakedCaseId),
            });
            this.$message.success("操作成功！");
            this.$refs.tablePage.getList();
          } catch (error) {}
          this.getList();
          break;
        case "copy":
          try {
            await getUserCopy(this.options.check.map((x) => x.freshlyBakedCaseId));
            this.$message.success("操作成功！");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  .dialog-footer {
    height: 80px;
  }
}
</style>
