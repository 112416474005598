var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _vm.active === 1
        ? _c("buyIndex", {
            attrs: { active: _vm.active },
            on: {
              "update:active": function ($event) {
                _vm.active = $event
              },
              resume: _vm.getResume,
            },
          })
        : _vm.active === 2
        ? _c("buyStep", {
            attrs: {
              active: _vm.active,
              resumeInfo: _vm.resumeInfo,
              options: _vm.options,
            },
            on: {
              "update:active": function ($event) {
                _vm.active = $event
              },
            },
          })
        : _vm.active === 3
        ? _c("buyList", {
            attrs: { active: _vm.active, optionsCom: _vm.options },
            on: {
              "update:active": function ($event) {
                _vm.active = $event
              },
              resume: _vm.getResume,
            },
          })
        : _vm.active === 4
        ? _c("buyRenew", {
            attrs: {
              active: _vm.active,
              resumeInfo: _vm.resumeInfo,
              options: _vm.options,
            },
            on: {
              "update:active": function ($event) {
                _vm.active = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }