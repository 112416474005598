<!-- 现烤师 -->
<template>
  <div class="wrap" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch v-model="queryParams" :getList="getList" :dropDownList="dropDownList" :isAdvancedSearch="false"
            :isBillDate="false" :isSearchInput="true" isSearchInputTitle="输入现烤师编码、现烤师名称" />
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="现烤师信息" class="brandInfo">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton :getList="getList" :isRecycleBtn="true" :isDeleteBtn="false" :multiple="multiple"
            @handleAdd="handleAdd" @handleDelete="handleDelete" @handleRedoDelete="dialogOptions.show = true" />
          <el-table :data="tableData" tooltip-effect="dark" @selection-change="handleSelectionChange"
            @row-click="handleRowClick" border v-loading="loadingTable" ref="multipleTable" :max-height="tableHeight"
            :height="tableHeight" style="width: 100%">
            <el-table-column align="center" type="selection" width="55"></el-table-column>
            <el-table-column align="center" type="index" width="80" label="序号">
            </el-table-column>
            <el-table-column align="center" prop="workerNo" label="现烤师编码" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-link type="primary" @click="handleDetail(scope.row)" target="_blank">
                  {{ scope.row.workerNo }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column prop="workerName" label="现烤师名称" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="freshlyBakedRoomName" label="现烤间名称" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column align="center" prop="remark" label="备注" show-overflow-tooltip>
            </el-table-column>

            <el-table-column align="center" prop="updateBy" label="修改人" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="updateTime" label="修改时间" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="createBy" label="创建人" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="createTime" label="创建时间" show-overflow-tooltip>
            </el-table-column>
          </el-table>
          <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="total"
            @selectAllCom="selectAll" />
        </div>
      </template>
    </cardTitleCom>
    <!-- 新增和单独编辑对话框 -->
    <c-dialog :title="title" :width="500" :showDialog.sync="dialogFormVisible">
      <template #content>
        <el-form :model="form" :rules="rules" ref="ruleForm" v-loading="dialogLoading">
          <el-form-item label="现烤师编码" :label-width="formLabelWidth" prop="workerNo">
            <el-input maxlength="20" class="brandInput" v-model="form.workerNo" autocomplete="off"
              placeholder="请输入现烤师编码"></el-input>
          </el-form-item>
          <el-form-item label="现烤师名称" :label-width="formLabelWidth" prop="workerName">
            <el-input maxlength="40" class="brandInput" v-model="form.workerName" autocomplete="off"
              placeholder="请输入现烤师名称"></el-input>
          </el-form-item>
          <el-form-item label="现烤间" :label-width="formLabelWidth" prop="freshlyBakedRoomId">
            <el-select clearable v-model="form.freshlyBakedRoomId">
              <el-option
                v-for="(item,index) of freshlyRoomList"
                  :key="index"
                  :label="item.freshlyBakedRoomName"
                  :value="item.freshlyBakedRoomId"
                  :disabled="item.key === 1"
              >{{item.freshlyBakedRoomName}}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input class="brandInput" v-model="form.remark" autocomplete="off" type="textarea"
              placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" :autosize="{ minRows: 2, maxRows: 4 }"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="addBrand">确 定</el-button>
      </template>
    </c-dialog>
    <!-- 回收站 -->
    <Dialog2 :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import {
  listBrand,
  addBrand,
  delBrand,
  getBrandDetail,
  updateBrand,
  restoreGoodsBrandInfo,
} from "@/api/freshlyBaked/base/teacher.js";
import { getFreshlyRoomList } from "@/api/freshlyBaked/base/room";
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import bottomPage from "@/views/components/bottomPage"; //底部分页
import seniorSearch from "@/views/components/seniorSearch"; //高级搜索
import operatingButton from "@/views/components/operatingButton"; //操作按钮
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import Dialog2 from "@/components/Dialog"; //回收站
export default {
  name: "teacher",
  dicts: ["common_status"],
  components: {
    seniorSearch,
    bottomPage,
    cardTitleCom,
    operatingButton,
    CDialog,
    Dialog2,
  },
  data() {
    return {
      dialogOptions: {
        type: "ShopInfo",
        show: false,
        title: "回收站",
        width: "75vw",
        data: {
          getListApi: listBrand,
          restoreListApi: restoreGoodsBrandInfo,
          type: 'decoration',
          id: "brandId",
          search: [
            {
              type: "filters",
              isRestore: true,
              model: "",
              filters: [
                { filter: "query", label: "全部" },
                { filter: "workerNos", label: "现烤师编码" },
                { filter: "workerNames", label: "现烤师名称" },
              ],
            },
            {
              type: "button",
              tip: "查询",
              btnType: "primary",
              click: "search",
              isRestore: true,
            },
          ],
          columns: [
            {
              prop: "workerNo",
              label: "现烤师编码",
              minWidth: 180,
            },
            {
              prop: "workerName",
              label: "现烤师名称",
              minWidth: 180,
            },
            {
              prop: "remark",
              label: "备注",
              minWidth: 120,
            },
            {
              prop: "updateBy",
              label: "修改人",
              minWidth: 120,
            },
            {
              prop: "updateTime",
              label: "修改时间",
              minWidth: 155,
            },
            {
              prop: "createBy",
              label: "创建人",
              minWidth: 120,
            },
            {
              prop: "createTime",
              label: "创建时间",
              minWidth: 155,
            },
          ],
        },
      },
      //搜索框搜索的条件
      dropDownList: [
        { parameter: "query", name: "全部" },
        { parameter: "workerNos", name: "现烤师编码" },
        { parameter: "workerNames", name: "现烤师名称" },
      ],
      ids: [], // 选中数组
      normal: 0, //状态正常
      disable: 1, //状态停用
      total: 0, //一共多少条
      title: "新增现烤师", //控制新增修改弹框标题
      dialogLoading: false, //表单
      loadingTable: false, //表格加载
      single: true, //修改和删除的禁用
      multiple: true, //单独修改禁用
      //   右边表格
      tableData: [
        {
          workerName: null, //现烤师名称
          workerNo: null, //现烤师编码
          createBy: null, //创建人
          createTime: null, //创建时间
          updateBy: null, //修改人
          updateTime: null, //修改时间
          remark: null, //备注
          workerId: null, //现烤师ID
          status: null, //现烤师状态
        },
      ],
      //表格选中内容
      multipleSelection: [],
      //新增、单独修改对话框
      dialogFormVisible: false,
      //对话框表单
      form: {
        workerName: "",
        workerNo: "",
        remark: "",
      },
      //选中数组的名称
      name: [],
      formLabelWidth: "120px", //表单宽度
      //新增表单校验
      rules: {
        workerNo: [
          {
            required: true,
            message: "现烤师编码不能为空",
            trigger: ["blur", "change"],
          },
          // {
          //   pattern: /^[0-9A-Za-z]{4}$/,
          //   message: "必须输入四位数字或英文字符",
          //   trigger: ["blur", "change"],
          // },
        ],
        freshlyBakedRoomId: [
          {
            required: true,
            message: "现烤间不能为空",
            trigger: ["blur", "change"],
          },
          // {
          //   pattern: /^[0-9A-Za-z]{4}$/,
          //   message: "必须输入四位数字或英文字符",
          //   trigger: ["blur", "change"],
          // },
        ],
        workerName: [
          {
            required: true,
            message: "现烤师名称不能为空",
            trigger: ["blur", "change"],
          },
          { pattern: /^.{1,40}$/, message: "请输入1 ~ 40个字符" },
        ],
      },
      //分页查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        accountId: null,
        tenantId: null,
        workerNo: null,
        workerName: null,
        status: null,
        zjm: null,
        sortNo: null,
        createUserId: null,
        updateUserId: null,
        deleteUserId: null,
        deleteBy: null,
        deleteTime: null,
        delFlag: 0
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40,
      freshlyRoomList: []
    };
  },
  created() {
    this.getList();
    this.freshlyRoomListFun();
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 140;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    freshlyRoomListFun () {
      getFreshlyRoomList({delFlag: 0}).then((response) => {
         this.freshlyRoomList = response.rows
      });
    },
    //还原刷新页面
    handleEvent() {
      this.loading = true;
      this.getList();
      this.loading = false;
    },
    handleResize() {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    },

    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true;
        const res = await listBrand({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total,
          delFlag: 0,
        });
        //重新渲染表格
        this.tableData = res.rows;
        //表格多选
        this.tableData.map((row) => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value);
          });
        });
        this.loadingTable = false;
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection();
        });
        this.getList();
      }
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    //  修改\点击编号
    async handleDetail(row) {
      this.title = "编辑现烤师";
      this.dialogFormVisible = true;
      this.dialogLoading = true;
      this.reset();
      getBrandDetail(row.workerId).then((response) => {
        this.form = response.data;
        this.dialogLoading = false;
      });
    },

    //当选择项发生变化时会触发
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.workerId); //单据id
      this.name = selection.map((item) => item.workerName);
      this.multipleSelection = this.ids;
      this.single = this.ids.length != 1;
      this.multiple = !this.ids.length;
    },
    // 新增按钮
    async handleAdd() {
      this.title = "新增现烤师";
      this.dialogFormVisible = true;
      // this.dialogLoading = true;
      this.reset(); // 表单重置;
      // 记录操
      // try {
      //   const res = await getGoodsBrandAutoNo();
      //   this.form.workerNo = res.data;
      // } catch (error) {
      //   this.dialogLoading = false;
      // }
    },
    //新增、修改对话框确认按钮
    async addBrand() {
      try {
        await this.$refs["ruleForm"].validate();
        if (this.form.workerId == null) {
          await addBrand(this.form);
          this.$message({
            message: "新增现烤师成功~",
            type: "success",
          });
        } else {
          await updateBrand(this.form);
          this.$message({
            message: "修改现烤师成功~",
            type: "success",
          });
        }
        await this.$refs["ruleForm"].resetFields();
        this.dialogFormVisible = false;
        this.reset(); //对话框重置
        this.getList();
      } catch (error) { }
    },
    //新增取消
    cancel() {
      this.reset();
      this.dialogFormVisible = false;
    },
    // 表单重置
    reset() {
      this.form = {
        workerName: "",
        workerNo: "",
        remark: "",
      };
      this.resetForm("ruleForm");
    },
    // 删除按钮
    handleDelete() {
      this.$confirm(`确定删除所选的<span style="color: #1890ff;"> ${this.multipleSelection.length} </span>个现烤师吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning',
      }).then(() => {
        return delBrand(this.multipleSelection); //删除请求
      })
        .then(() => {
          this.getList(); //渲染列表
          this.$modal.msgSuccess("删除成功");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /** 查询表格列表 */
    async getList() {
      this.loadingTable = true;
      const { rows, total } = await listBrand(this.queryParams);
      this.tableData = rows;
      this.total = total;
      this.loadingTable = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  .brandInfo {
    height: calc(100% - 94px);
    //源单信息表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
// ::v-deep .el-table .cell.el-tooltip {
//   min-height: 29px;
// }
</style>
