var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "现烤APP开通" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c("div", { staticClass: "head" }, [
              _c("div", {
                staticClass: "head-logo",
                style:
                  "background-image:url(" +
                  require("@/assets/images/fpLogo.png") +
                  ");",
              }),
              _c("div", [
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "14px", "line-height": "28px" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          "font-weight": "bold",
                        },
                      },
                      [_vm._v("现烤间APP")]
                    ),
                    _c("div", [
                      _vm._v(
                        " 用户可以根据实时消费情况提前制定生产计划，通过现烤APP实现即时生产，即时售卖打造新鲜烘焙，现烤现做，不卖隔夜面包/蛋糕，保证真材实料。满足市场需求，避免浪费，为商户创造更好的口碑，提高品牌的影响力。 "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _c(
                      "div",
                      { staticClass: "button", on: { click: _vm.goBuy } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.price) +
                            " " +
                            _vm._s(
                              _vm.isBuy == "buy" ? "立即开通" : "立即续费"
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "el-link",
                      {
                        staticClass: "text-button",
                        attrs: { type: "primary" },
                        on: { click: _vm.goList },
                      },
                      [_vm._v("查询订单列表>")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        { attrs: { hideCard: true } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "x-fc", staticStyle: { "padding-top": "50px" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex1",
                    staticStyle: { "margin-left": "100px" },
                  },
                  _vm._l(_vm.introductionList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "y-start marB20" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "fontBold",
                            staticStyle: { "margin-bottom": "10px" },
                          },
                          [_vm._v(_vm._s(item.title))]
                        ),
                        _c("div", { staticStyle: { "margin-left": "8px" } }, [
                          _vm._v(_vm._s(item.content)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _c("el-image", {
                  staticClass: "marR50",
                  staticStyle: { width: "35%", height: "80%" },
                  attrs: { src: require("@/assets/images/bh-panel.png") },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }