<template>
  <div class="wrap">
    <buyIndex v-if="active === 1" :active.sync="active" @resume="getResume" />
    <buyStep v-else-if="active === 2" :active.sync="active" :resumeInfo="resumeInfo" :options='options' />
    <buyList v-else-if="active === 3" :active.sync="active" @resume="getResume" :optionsCom='options' />
    <buyRenew v-else-if="active === 4" :active.sync="active" :resumeInfo="resumeInfo" :options='options' />
  </div>
</template>
<script>
import {
  getTradeProductOrder,
  getTradeProductToRenew,
  tradeProductRenew,
  getOrderDetail,
  generateOrder,
  orderPay,
  getOrderState,
} from "@/api/produce/decoration/index";
import buyIndex from './components/buy-index.vue'
import buyList from '@/components/openApplet/buy-list.vue'
import buyStep from '@/components/openApplet/buy-step.vue'
import buyRenew from '@/components/openApplet/buy-Renew.vue'
export default {
  name: 'goBuyActivate',
  components: { buyIndex, buyList, buyStep, buyRenew },
  data() {
    return {
      active: 1,
      resumeInfo: {},
      options: {
        getOrderDetailApi: getOrderDetail,
        generateOrderApi: generateOrder,
        orderPayApi: orderPay,
        getOrderStateApi: getOrderState,
        getTradeProductToRenewApi: getTradeProductToRenew,
        tradeProductRenewApi: tradeProductRenew,
        getTradeProductOrderApi: getTradeProductOrder,
      }
    }
  },
  mounted() { },
  methods: {
    getResume(val) {
      this.resumeInfo = val || {}
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  background-color: #eaeaea;
  .top-btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
  }
}
</style>
