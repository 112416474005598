var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("TablePage", {
        ref: "tablePage",
        on: { handleEvent: _vm.handleEvent },
        model: {
          value: _vm.options,
          callback: function ($$v) {
            _vm.options = $$v
          },
          expression: "options",
        },
      }),
      _c("Dialog", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
      _c("c-dialog", {
        attrs: {
          title: "选择现烤师",
          width: 700,
          showDialog: _vm.dialogFormVisible,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c(
                    "div",
                    { staticClass: "workerBox" },
                    _vm._l(_vm.workerList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "li",
                          class: _vm.workerIndex == index ? "activeLi" : "",
                          on: {
                            click: function ($event) {
                              return _vm.workerClick(index)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.workerName))]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticStyle: { flex: "1" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "ruleForm",
                          attrs: { model: _vm.form, rules: _vm.rules },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "生产数量",
                                "label-width": _vm.formLabelWidth,
                                prop: "freshlyBakedFinishQty",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "brandInput",
                                attrs: {
                                  maxlength: "20",
                                  autocomplete: "off",
                                  placeholder: "请输入生产数量",
                                },
                                model: {
                                  value: _vm.form.freshlyBakedFinishQty,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "freshlyBakedFinishQty",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "form.freshlyBakedFinishQty",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "报损数量",
                                "label-width": _vm.formLabelWidth,
                                prop: "freshlyBakedScrapQty",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "brandInput",
                                attrs: {
                                  maxlength: "40",
                                  autocomplete: "off",
                                  placeholder: "请输入报损数量",
                                },
                                model: {
                                  value: _vm.form.freshlyBakedScrapQty,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "freshlyBakedScrapQty",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "form.freshlyBakedScrapQty",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.addBrand } },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }